<template>
  <div>
    <v-row class="pa-4 ma-0 mb-4 d-flex flex-column"> 
   <div class="d-flex">
    <div class="font-weight-bold primaryColor">Positions</div>    <v-progress-circular class="ml-2" indeterminate v-if="positionCountLoader" size="18" :width="2" color="black">
        </v-progress-circular>
   </div>
    <!-- <div class="d-flex flex-column mt-2">
              <div class="mt-2 d-flex" v-if="positionCountObj && positionCountObj.exch">
              <li class="list-style-none countli min-w-100  fsize16 px-4 py-2">Exchange</li> <li class="list-style-none fsize14 px-4 py-2" v-for="(item, i) of positionCountObj.exch " :key="i">{{item}}</li>
            </div>
            <div class="mt-2 d-flex" v-if="positionCountObj && positionCountObj.count">
              <li class="list-style-none countli min-w-100  fsize16 px-4 py-2">Count</li> <li class="list-style-none fsize14 px-4 py-2" v-for="(item, i) of positionCountObj.count " :key="i">{{item}}</li>
            </div>
            </div> -->
            <v-card class="mt-2">
              <v-simple-table id="holding_count_table" v-if="positionCountObj" > 
              <thead>
                <tr>
                  <th class="fsize12 font-weight-normal tableContent text-center">Exchange</th>
                  <th class="fsize12 font-weight-normal tableContent text-center">Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idex) in positionCountObj" :key="idex">
                  <td class="text-center tableContent">{{ i.exchange }}</td>
                  <td class="text-center tableContent">{{ i.ct }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            </v-card>
  </v-row>
    <v-row class="pa-4 ma-0 d-flex flex-column"> 
    <div class="d-flex">
      <div class="font-weight-bold primaryColor">Holdings</div>    <v-progress-circular class="ml-2" indeterminate v-if="hodingsCountLoader" size="18" :width="2" color="black">
        </v-progress-circular>
    </div>
    <!-- <div class="d-flex flex-column mt-2">
              <div class="mt-2 d-flex" v-if="holdingsCountList && holdingsCountList.exchange">
              <li class="list-style-none countli min-w-100  fsize16 px-4 py-2">Exchange</li> <li class="list-style-none fsize14 px-4 py-2" >{{holdingsCountList.exchange}}</li> <li class="list-style-none fsize14 px-4 py-2" >BSE</li>
            </div>
            <div class="mt-2 d-flex" v-if="holdingsCountList && holdingsCountList.ct">
              <li class="list-style-none countli min-w-100  fsize16 px-4 py-2">Count</li> <li class="list-style-none fsize14 px-4 py-2" >{{ holdingsCountList.ct}}</li> <li class="list-style-none fsize14 px-4 py-2" >0</li>
            </div>
            </div> -->

            <v-card class="mt-2">
              <v-simple-table id="holding_count_table" v-if="holdingsCountList" > 
              <thead>
                <tr>
                  <th class="fsize12 font-weight-normal tableContent text-center">Exchange</th>
                  <th class="fsize12 font-weight-normal tableContent text-center">Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, idex) in holdingsCountList" :key="idex">
                  <td class="text-center tableContent">{{ i.exchange }}</td>
                  <td class="text-center tableContent">{{ i.ct }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            </v-card>
  </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    data(){
        return {

        }
    },
    computed:{
        ...mapState("uploadFile", ['positionCountObj' , 'holdingsCountList', 'positionCountLoader', 'hodingsCountLoader']),
    },
    created(){
        this.$store.dispatch('uploadFile/getpositionsCount')
        this.$store.dispatch('uploadFile/getHoldingsCount')
    }
}
</script>

<style>

</style>